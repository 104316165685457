<template
  ><div>
    <h6>3 seconds redirect to AuthResponse</h6>
  </div>
</template>

<script>
export default {
  name: 'AuthResponseTest',
  data() {
    return {}
  },
  mounted() {
    let type = this.$route.query.type
    // let random = Math.floor(Math.random() * 2)
    let random = 1
    // console.log(random)
    setTimeout(() => {
      if (random) {
        this.$router.push(`/pages/authResponse?type=${type}&status=success`)
      } else {
        this.$router.push(`/pages/authResponse?type=${type}&status=false`)
      }
    }, 1000)
  }
}
</script>
